import './Animations.css'; 

window.parentAnimElementsListener = [];
window.animElementsListener = [];

export function AddParentElement(className){    
    const element = document.querySelector(`.${className}`);
    window.parentAnimElementsListener.push({name: className, element: element });  

    const animInterval = setInterval(() => {
        if(document.querySelector(`.${className}`)){
            if (window.pageYOffset > document.querySelector(`.${className}`).offsetTop) {
                UpdateAnimatedParentElements();
                UpdateAnimatedChildElements();
            }
            clearInterval(animInterval);
        }
    }, 100); 
}

export function AddAnimationToElements(elements){
    for(let i=elements.length; i>0; i--){
        const element = window.animElementsListener[elements[i-1]];
        setTimeout(() => {
            element.classList.add('active_anim');
        }, 50);
        window.animElementsListener.splice(i-1, 1);
    }
}

export function SetupAnimatedObjects(index){
    const animElements = document.querySelectorAll(`.${window.parentAnimElementsListener[index].name} .anim`);   
    for (let i = 0; i < animElements.length; i++) {
        window.animElementsListener.push(animElements[i]);
    }
    window.parentAnimElementsListener.splice(index, 1);
}

// Update
export function UpdateAnimatedParentElements(){
    const distanceY = window.pageYOffset;
    
    for (let i = 0; i < window.parentAnimElementsListener.length; i++) {     
        if (distanceY + window.innerHeight >= window.parentAnimElementsListener[i].element.offsetTop + 10) {            
            SetupAnimatedObjects(i); 
        }
    }
}

export function UpdateAnimatedChildElements(){
    const distanceY = window.pageYOffset;

    if(window.animElementsListener.length > 0){
        let animElementsArray = [];   
        for (let i = 0; i < window.animElementsListener.length; i++) { 
            if (distanceY + window.innerHeight >= window.animElementsListener[i].offsetTop + 50) {
                animElementsArray.push(i);            
            }
        }   
        if(animElementsArray.length > 0){
            AddAnimationToElements(animElementsArray);
            animElementsArray = [];
        }
    }
    
}