import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';

//Services
import * as windowEvents from './services/WindowEvents.js';

//Animations
import * as animation from './services/Animations/Animations.js';

//Components
import Hero from './components/Hero/Hero.js';
const SpotlightComponent = React.lazy(() => import('./components/Spotlight/Spotlight.js'));
const ProjetosComponent = React.lazy(() => import('./components/Projetos/Projetos.js'));
const DescricaoComponent = React.lazy(() => import('./components/Descricao/Descricao.js'));
const PremiosComponent = React.lazy(() => import('./components/Premios/Premios.js'));
const ClientesComponent = React.lazy(() => import('./components/Clientes/Clientes.js'));
const BlogComponent = React.lazy(() => import('./components/Blog/Blog.js'));
const BigNumbersComponent = React.lazy(() => import('./components/BigNumbers/BigNumbers.js'));
const DestaquesComponent = React.lazy(() => import('./components/Destaques/Destaques.js'));
const FooterComponent = React.lazy(() => import('./components/Footer/Footer.js'));


ReactDOM.render(
  <React.StrictMode>
    <Hero />
    <Suspense fallback={<div></div>}>
      <SpotlightComponent />
      <DescricaoComponent />
      <ProjetosComponent />      
      <PremiosComponent />
      <ClientesComponent />
      <BlogComponent />
      <BigNumbersComponent />
      <DestaquesComponent />
      <FooterComponent />
    </Suspense>    
  </React.StrictMode>,
  document.getElementById('root')
);

