import { useEffect } from 'react';
import './Hero.css';
import videoBackground from './assets/ChromaGarden.mp4';
import { AddParentElement, UpdateAnimatedParentElements, UpdateAnimatedChildElements } from './../../services/Animations/Animations.js';

function Hero() {

    useEffect(() => {
        // Setup animation
        AddParentElement('hero');  
        UpdateAnimatedParentElements();
        UpdateAnimatedChildElements();        
    });

    return (
        <div className="hero">
            <div className="hero_video_container">
                <video src={videoBackground} type="video/mp4"  className="hero-video" autoPlay loop muted></video>   
            </div>   
            <div className="hero_container">    
                <div className="hero_text_container">
                    <p className="hero_subtitle anim anim_opacity">TECNOLOGIA, PUBLICIDADE, EVENTOS</p>
                    <h1 className="hero_title anim">Criamos experiências de impacto para as empresas e significado para o público.</h1>               
                </div>
            </div>
        </div>
    );
}

export default Hero;